import {
  Box,
  Dialog,
  makeStyles,
  useTheme,
  Typography,
  Button
} from '@material-ui/core';
import React, { useState } from 'react';
import { useNotif, useToggle } from 'src/hooks';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    height: 240
  },
  fileInput: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    cursor: 'pointer'
  },
  defaultImg: {
    width: '150px',
    height: '150px',
    [theme.breakpoints.down('sm')]: {
      width: '100px',
      height: '100px'
    }
  }
}));

/**
 * @param {Object} props
 * @param {Boolean} [props.open=false]
 * @param {Function} [props.onClose=() => {}]
 * @param {Boolean} [props.multiple=false]
 * @param {(data: File | FileList) => void} [props.onUpload=() => {}]
 * @returns {React.ReactElement}
 *
 * File uploader component.
 **/
const FileUpload = ({
  open = false,
  onClose = () => {},
  multiple = false,
  onUpload = () => {}
}) => {
  const theme = useTheme();
  const classNames = useStyles();

  const notify = useNotif();

  // const [attachments, setAttachments] = useState([]);

  const [isOver, dragEnter, dragLeave] = useToggle();

  /**
   *
   * @param {React.MouseEvent<HTMLInputElement>} event
   */
  function preventDefaultFileOpener(event) {
    // event.preventDefault();
    // event.currentTarget.focus();
  }

  /**
   *
   * @param {FileList} files
   */
  function validateFiles(files) {
    if (files.length === 0) {
      notify.error('Please upload a valid file.');
      return;
    }

    onUpload(multiple ? files : files[0]);
  }

  /**
   *
   * @param {React.DragEvent<HTMLInputElement>} event
   */
  function dropFiles(event) {
    event.preventDefault();

    const files = event.dataTransfer.files;
    validateFiles(files);
  }

  /**
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event
   */
  function changeFiles(event) {
    event.preventDefault();

    const files = event.target.files;
    validateFiles(files);
  }

  /**
   *
   * @param {React.ClipboardEvent<HTMLInputElement>} event
   */
  function pasteFiles(event) {
    console.log(event);
    event.preventDefault();
    const files = event.clipboardData.files;
    console.log(files);
  }

  /**
   * Handles the browse button click event.
   * @param {React.MouseEvent<HTMLButtonElement>} event
   */
  function browse(event) {
    event.stopPropagation();
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <Box
        m={2}
        className={classNames.container}
        border={
          '4px dashed' + (isOver ? theme.palette.primary.main : grey[400])
        }
      >
        <input
          type="file"
          multiple={multiple}
          className={classNames.fileInput}
          onDrop={dropFiles}
          onChange={changeFiles}
          onPaste={pasteFiles}
          // onClick={preventDefaultFileOpener}
          onDragEnter={dragEnter}
          onDragLeave={dragLeave}
        />
        <Box display="flex" alignItems="center" gridGap={24}>
          <img
            className={classNames.defaultImg}
            alt="Files"
            src="/static/images/addFiles.svg"
          />
          <Box>
            <Typography variant="h3" color="textSecondary">
              Drop or Browse
            </Typography>
            {/* <Button
              fullWidth
              size="small"
              variant="outlined"
              color="primary"
              style={{ marginTop: 8 }}
              onClick={browse}
            >
              Browse
            </Button> */}
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default FileUpload;
