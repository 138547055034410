import React from 'react';
import moment from 'moment';
import QRCode from 'react-qr-code';
import { getWFStatus, showNewlines } from 'src/helper';
import { Box, Grid, Typography, makeStyles, Paper } from '@material-ui/core';
import getOrderStatus from 'src/helpers/getOrderStatus';
import { INVENTORY_TYPES } from 'src/constants';

const useStyles = makeStyles(theme => ({
  headerCompanyDetails: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  headerQrDetailsDirection: {
    ['& span']: {
      marginLeft: '1em'
    },

    [theme.breakpoints.down('xs')]: {
      marginLeft: '1rem',
      textAlign: 'left',
      ['& span']: {
        display: 'block',
        margin: 0,
        padding: 0
      }
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '1rem',
      textAlign: 'left',
      ['& span']: {
        display: 'block',
        margin: 0,
        padding: 0
      }
    }
  },
  contentDirection: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  }
}));

const GridItem = ({
  label,
  value,
  variant = 'body1',
  visible = true,
  allowNewLines = false
}) => {
  if (visible == false) return null;
  return (
    <Grid item>
      <Typography variant="caption">{label}</Typography>

      {allowNewLines ? (
        <Typography
          gutterBottom
          variant={variant}
          color="textPrimary"
          dangerouslySetInnerHTML={showNewlines(value)}
        />
      ) : (
        <Typography gutterBottom variant={variant} color="textPrimary">
          {value}
        </Typography>
      )}
    </Grid>
  );
};

const JVDetails = ({ jv, wf, biz, wfStatus }) => {
  const classes = useStyles();

  return (
    <Paper
      component={Box}
      p={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2 }}
      height="100%"
    >
      <Box mb={2} display="flex" justifyContent="space-between">
        <Box className={classes.headerCompanyDetails}>
          <Typography variant="h1" color="textPrimary">
            <b>{biz.name}</b>
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {biz.ad1}
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {biz.ad2}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection={{
            xs: 'row-reverse',
            sm: 'row-reverse',
            md: 'row',
            lg: 'row',
            xl: 'row'
          }}
          justifyContent={{
            xs: 'flex-start',
            sm: 'flex-start',
            md: 'flex-end',
            lg: 'flex-end',
            xl: 'flex-end'
          }}
        >
          <Box mr={2}>
            <Box>
              <Typography
                align="right"
                variant="h2"
                className={classes.headerQrDetailsDirection}
              >
                <b>{jv?._rm?.sJCd}</b>
              </Typography>
              <Typography
                align="right"
                variant="h3"
                className={classes.headerQrDetailsDirection}
              >
                {jv.RefNo}
              </Typography>
              <Box>
                <Typography
                  align="right"
                  variant="h5"
                  className={classes.headerQrDetailsDirection}
                >
                  <span> ID: {jv.jid}</span>
                  <span> Type: {jv.JCd}</span>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box p={1} bgcolor="#FFF">
            <QRCode
              value={`${window.location.origin}/app/trans/${jv.jid}`}
              level="H"
              size={128}
            />
          </Box>
        </Box>
      </Box>

      <Box mb={2}>
        <Grid
          container
          justifyContent="space-between"
          className={classes.contentDirection}
        >
          {jv?._rm.brch?.show?.jv ? (
            <GridItem
              variant="h5"
              label={jv?._rm?.brch?.label_singular ?? ''}
              value={jv.sBrch ? jv.sBrch : 'None'}
            />
          ) : null}

          <GridItem variant="h5" label="Ref #" value={jv.RefNo} />

          {jv._rm.captions?.extFld1 ? (
            <GridItem
              variant="h5"
              label="Date"
              value={moment(jv.extFld1).format('MM/DD/YYYY')}
            />
          ) : (
            <GridItem
              variant="h5"
              label="Date"
              value={moment(jv.jDate).format('MM/DD/YYYY')}
            />
          )}
          {jv._rm.captions?.jDate && (
            <GridItem
              variant="h5"
              label={jv._rm.captions.jDate}
              value={moment(jv.jDate).format('MM/DD/YYYY')}
            />
          )}
          {jv._rm.showDueDate ? (
            <GridItem
              variant="h5"
              label="Due Date"
              value={moment(jv.dueDate).format('MM/DD/YYYY')}
            />
          ) : null}

          {[
            INVENTORY_TYPES.PURCHASE_ORDER,
            INVENTORY_TYPES.SALES_ORDER
          ].includes(jv?._rm?.ixInvType ?? INVENTORY_TYPES.DISABLED) ? (
            <GridItem
              variant="h5"
              label="Order Status"
              value={getOrderStatus(jv?.ixOrderStatus)}
            />
          ) : null}

          {jv._rm.showStatus ? (
            <GridItem
              variant="h5"
              label={jv?._rm?.labels?.Status ?? 'Status'}
              value={jv.sStatus}
            />
          ) : null}

          {jv._rm.showWF ? (
            <GridItem
              variant="h5"
              label={jv?._rm?.labels?.wfStatus ?? 'WF Status'}
              value={getWFStatus(wf, wfStatus)}
            />
          ) : null}
        </Grid>
      </Box>

      <Box mb={2}>
        <Grid container direction="column">
          <GridItem
            label={jv?._rm?.labels?.Name ?? 'Name'}
            value={jv.Name}
            variant="h3"
          />
          {jv._rm?.showAddress ? (
            <GridItem label="Address" value={jv.Address} />
          ) : null}
        </Grid>
      </Box>

      {Object.keys(jv?._rm?.experimental?.jv_sub ?? {}).length > 0 ? (
        <Box mb={2}>
          <Grid container spacing={2}>
            {Object.entries(jv?._rm?.experimental?.jv_sub ?? {}).map(
              ([key, value], i) =>
                value.show === false ? null : (
                  <Grid
                    item
                    key={i}
                    xs={value.col.xs}
                    sm={value.col.sm}
                    md={value.col.md}
                    lg={value.col.lg}
                    xl={value.col.xl}
                  >
                    <Typography variant="caption">
                      {jv?._rm[`caption${key}`]}
                    </Typography>
                    <Typography gutterBottom variant="h5" color="textPrimary">
                      {jv[`s${key}`]}
                    </Typography>
                  </Grid>
                )
            )}
          </Grid>
        </Box>
      ) : null}

      <Box mb={2}>
        <Grid container>
          <GridItem label="Particulars" value={jv.Particulars} />
        </Grid>
      </Box>

      <Grid>
        <GridItem
          label="Notes"
          value={jv?.Remarks ? jv?.Remarks : ''}
          allowNewLines={true}
        />
      </Grid>
    </Paper>
  );
};

export default React.memo(JVDetails);
