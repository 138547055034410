/**
 * Modifies the given columns array to make all columns before the last sticky
 * column sticky as well, and removes hidden columns.
 *
 * @param {Array<column>} columns The array of column definitions, each with a
 *   sticky property.
 * @return {{columns:Array<column>,lastStickyIndex:number}} A new array with the same columns, but with the sticky
 *   property modified according to the above rule, and without any hidden
 *   columns.
 */
function filterColumns(columns) {
  const res = [];

  let stickyFound = false,
    lastStickyIndex = -1;
  for (let i = columns.length - 1; i >= 0; i--) {
    if (!columns[i]?.hide) {
      if (!stickyFound && columns[i]?.sticky) {
        stickyFound = true;
        lastStickyIndex = i;
      }

      res.unshift(stickyFound ? { ...columns[i], sticky: true } : columns[i]);
    }
  }

  return { columns: res, lastStickyIndex };
}

export default filterColumns;
