import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Box
} from '@material-ui/core';

const ColorLibConnector = withStyles(theme => ({
  alternativeLabel: {
    top: 22
  },
  active: {
    '& $line': {
      background: theme.palette.primary.main
    }
  },
  completed: {
    '& $line': {
      background: theme.palette.primary.main
    }
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1
  }
}))(StepConnector);

const useStyles = makeStyles(theme => ({
  stepper: {
    backgroundColor: 'transparent',
    boxShadow: 'none'
  },
  step: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  active: {
    background: theme.palette.primary.main,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
  },
  completed: {
    background: theme.palette.primary.main
  }
}));

/**
 *
 * @param {object} props
 * @param {number} props.activeStep
 * @param {Array<{label: string, icon: React.JSX.Element}>} props.steps
 * @returns
 */
const CustomizedSteppers = ({ activeStep = 0, steps = [] }) => {
  const classes = useStyles();

  return (
    <Box width="100%">
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorLibConnector />}
        className={classes.stepper}
      >
        {steps.map(({ label, icon }) => (
          <Step key={label}>
            <StepLabel
              StepIconComponent={props => (
                <div
                  className={clsx(classes.step, {
                    [classes.active]: props.active,
                    [classes.completed]: props.completed
                  })}
                >
                  {icon}
                </div>
              )}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default CustomizedSteppers;
