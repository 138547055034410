import React, { useState, useEffect } from 'react';
import {
  Box,
  DialogActions,
  Divider,
  Button,
  makeStyles
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { getDateValues } from './utils';

const useStyles = makeStyles({
  year: {
    '& .MuiPickersBasePicker-pickerView': {
      width: '100%',
      maxWidth: 'none',
      minWidth: 0,
      '& .MuiPickersYearSelection-container': {
        padding: '0 1em',
        display: 'flex',
        gap: '2em 4em',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
        alignItems: 'stretch',
        overflowY: 'initial',
        '& .MuiPickersYear-yearSelected': {
          margin: 0,
          fontWeight: 700
        }
      },
      '& .MuiPickersMonthSelection-container': {
        width: '100%'
      }
    },
    '& .MuiToolbar-root': {
      width: '180px'
    }
  }
});

const Month = ({
  handleChangeDatePickerValue,
  handleCloseDialog,
  dateValues,
  type,
  defaultDatePicker,
  prefixText,
  ...data
}) => {
  const classNames = useStyles();
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));

  const handleChange = val => {
    setDate(moment(val).format('YYYY-MM-DD'));
  };

  const handleOk = () => {
    handleChangeDatePickerValue(
      getDateValues.month({
        values: { dt1: date, dt2: date },
        prefixText: prefixText || 'month'
      })
    );
    handleCloseDialog();
  };

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Box width="100%" className={classNames.year}>
          <DatePicker
            label="Select Year and Month"
            fullWidth
            openTo="month"
            variant="static"
            orientation="landscape"
            views={['month', 'year']}
            value={date}
            onChange={handleChange}
            allowKeyboardControl
            inputVariant="outlined"
            autoOk={true}
          />
        </Box>
      </MuiPickersUtilsProvider>
      <Divider />
      <DialogActions style={{ justifyContent: 'space-between' }}>
        <Button color="primary" onClick={handleCloseDialog}>
          Cancel
        </Button>

        <Button color="primary" variant="contained" onClick={handleOk}>
          Ok
        </Button>
      </DialogActions>
    </>
  );
};

export default Month;
