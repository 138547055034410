import { useState } from 'react';
import { Request } from 'src/helpers';
import { useNotif } from 'src/hooks';
import { trans } from 'src/lib/endpoints';

/**
 *
 * @param {Request} request
 */
const useTransView = request => {
  const notify = useNotif();
  const [transViewProps, setTransViewProps] = useState({
    details: {},
    open: false
  });

  /**
   *
   * @param {number|string} jid
   */
  async function viewTransDetails(jid) {
    if (!jid) return;

    setTransViewProps({ details: {}, open: true });
    const res = await request.get(trans.details(jid));

    if (res.success) {
      setTransViewProps({
        details: res.data?.jv || {},
        open: true
      });
    } else {
      notify.error(res.errorMessage);
      setTransViewProps({ details: {}, open: false });
    }
  }

  function closeTransView() {
    setTransViewProps({ details: {}, open: false });
  }

  return { viewTransDetails, transViewProps, closeTransView };
};

export default useTransView;
