function qtyPCToCS({ libQtyCS = 0, libQtySC = 0, qty = 0, keyProp = '' }) {
  let remainder = qty,
    qtyCS = 0,
    qtySC = 0;

  if (qty <= 0) {
    return {
      [`${keyProp}_CS`]: qtyCS,
      [`${keyProp}_SC`]: qtySC,
      [`${keyProp}_PC`]: remainder
    };
  }

  if (libQtyCS > 0) {
    qtyCS = Math.trunc(remainder / libQtyCS);
    remainder = remainder % libQtyCS;
  }

  if (libQtySC > 0) {
    qtySC = Math.trunc(remainder / libQtySC);
    remainder = remainder % libQtySC;
  }

  return {
    [`${keyProp}_CS`]: qtyCS,
    [`${keyProp}_SC`]: qtySC,
    [`${keyProp}_PC`]: remainder
  };
}

export default qtyPCToCS;
