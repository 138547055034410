import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { v4 as uuid } from 'uuid';

const INITIAL_STATE = {
  dateRange: {
    dt1: '',
    dt2: '',
    textValue: ''
  },
  dateRange2: {
    dt1: null,
    dt2: null,
    textValue: ''
  },
  showSN: false,
  showSN2: false,
  showCS: false,
  showSC: false,
  showBN: false,
  showProdDate: false,
  showExpDate: false,
  showInvAge: false,
  showBeg: false,
  loadAll: false,
  activeVariation: 0,
  activeWh: [],
  activeProdCat: {},
  activeProdSubCat: {},
  ixProdSubLink1: 0,
  sProdSubLink1: '',
  ixProdSubLink2: 0,
  sProdSubLink2: '',
  ixProdSubLink3: 0,
  sProdSubLink3: '',
  ixProdSubLink4: 0,
  sProdSubLink4: '',
  ixProdSubLink5: 0,
  sProdSubLink5: '',
  activeBrch: 0,
  showProdDetails: false,
  showSubWHCol: false,
  subWarehouse: {
    show: false,
    ixSubAcc: 0,
    sSubAcc: '',
    ixSubType: 0,
    sSubType: ''
  },
  page: 1,
  limit: 100,
  lstProdCat: [],
  result: []
};

const isSCEnabled = (data, prop) => {
  for (let i = 0; i < data.length; i++) {
    const item = data[i];

    if ((item?.[prop] ?? 0) > 0) return true;
  }

  return false;
};

const getInventoryAge = (start, end) =>
  typeof start === 'undefined' ||
  start === null ||
  start === '' ||
  typeof end === 'undefined' ||
  end === '' ||
  end === null
    ? 0
    : moment.duration(moment(end).diff(moment(start))).asDays();

const slice = createSlice({
  name: 'endingInventory',
  initialState: INITIAL_STATE,
  reducers: {
    storeLstProdCat(state, action) {
      state.lstProdCat = action.payload;
    },
    storeActiveVariation(state, action) {
      state.activeVariation = action.payload;
      if (action.payload === 101) {
        state.subWarehouse.ixSubAcc = 0;
        state.subWarehouse.sSubAcc = '';
      }
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    storeDateRange(state, action) {
      state.dateRange = action.payload;
    },
    storeDateRange2(state, action) {
      state.dateRange2 = action.payload;
    },
    storeActiveWH(state, action) {
      state.activeWh = action.payload;
    },
    storeActiveBranch(state, action) {
      state.activeBrch = action.payload;
    },
    storeShowProdDetails(state, action) {
      state.showProdDetails = action.payload;
    },
    storePageItems(state, action) {
      const showBeg = action.payload.some(
        item => 'qtyBeg' in item || 'totIN' in item || 'totOUT' in item
      );
      state.result = action.payload.map(item => ({
        ...item,
        invAge: getInventoryAge(item?.p_jDate, state.dateRange.dt2),
        id: uuid()
      }));
      state.showCS = isSCEnabled(action.payload, 'libQtyCS') && !showBeg;
      state.showSC = isSCEnabled(action.payload, 'libQtySC') && !showBeg;
      state.showBN = action.payload.some(item => (item?.BN ?? '') !== '');
      state.showProdDate = action.payload.some(
        item => (item?.ProdDate ?? '') !== ''
      );
      state.showExpDate = action.payload.some(
        item => (item?.ExpDate ?? '') !== ''
      );
      state.showSN = action.payload.some(item => (item?.SN ?? '') !== '');
      state.showSN2 = action.payload.some(item => (item?.SN2 ?? '') !== '');
      state.showInvAge = action.payload.some(item => 'p_jDate' in item);
      state.loadAll = false;
      state.showBeg = showBeg;
      state.showSubWHCol = state.activeVariation === 101;
    },
    storeResult(state, action) {
      const showBeg = action.payload.data.some(
        item => 'qtyBeg' in item || 'totIN' in item || 'totOUT' in item
      );
      state.result = action.payload.data.map(item => ({
        ...item,
        invAge: getInventoryAge(item?.p_jDate, state.dateRange.dt2),
        id: uuid()
      }));
      state.showCS = isSCEnabled(action.payload.data, 'libQtyCS') && !showBeg;
      state.showSC = isSCEnabled(action.payload.data, 'libQtySC') && !showBeg;
      state.showBN = action.payload.data.some(item => (item?.BN ?? '') !== '');
      state.showProdDate = action.payload.data.some(
        item => (item?.ProdDate ?? '') !== ''
      );
      state.showExpDate = action.payload.data.some(
        item => (item?.ExpDate ?? '') !== ''
      );
      state.showSN = action.payload.data.some(item => (item?.SN ?? '') !== '');
      state.showSN2 = action.payload.data.some(
        item => (item?.SN2 ?? '') !== ''
      );
      state.showInvAge = action.payload.data.some(item => 'p_jDate' in item);
      state.page = 1;
      state.loadAll = true;
      state.showBeg = showBeg;
      state.showSubWHCol = state.activeVariation === 101;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setLimit(state, action) {
      state.limit = action.payload;
    },
    storeActiveProdCat(state, action) {
      state.activeProdCat = action.payload;
    },
    storeActiveProdSubCat(state, action) {
      state.activeProdSubCat = action.payload;
    },
    setProdSubLink1(state, action) {
      state.ixProdSubLink1 = action.payload.ixProdSubLink1;
      state.sProdSubLink1 = action.payload.sProdSubLink1;
    },
    setProdSubLink2(state, action) {
      state.ixProdSubLink2 = action.payload.ixProdSubLink2;
      state.sProdSubLink2 = action.payload.sProdSubLink2;
    },
    setProdSubLink3(state, action) {
      state.ixProdSubLink3 = action.payload.ixProdSubLink3;
      state.sProdSubLink3 = action.payload.sProdSubLink3;
    },
    setProdSubLink4(state, action) {
      state.ixProdSubLink4 = action.payload.ixProdSubLink4;
      state.sProdSubLink4 = action.payload.sProdSubLink4;
    },
    setProdSubLink5(state, action) {
      state.ixProdSubLink5 = action.payload.ixProdSubLink5;
      state.sProdSubLink5 = action.payload.sProdSubLink5;
    },
    clearData(state, action) {
      state.result = [];
    },
    resetState() {
      return INITIAL_STATE;
    },
    clear() {
      return INITIAL_STATE;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    },
    setSubWarehouse(state, action) {
      state.subWarehouse = action.payload;
    }
  }
});

const endingInventoryReducer = slice.reducer;

export const {
  storeDateRange,
  storeDateRange2,
  storeActiveWH,
  storeActiveProdCat,
  storeActiveProdSubCat,
  storeActiveBranch,
  storeShowProdDetails,
  storeActiveVariation,
  storeLstProdCat,
  resetState,
  restore,
  clear,
  clearData,
  storeResult,
  storeInitialResult,
  storePageItems,
  setPage,
  setProdSubLink1,
  setProdSubLink2,
  setProdSubLink3,
  setProdSubLink4,
  setProdSubLink5,
  setLimit,
  setSubWarehouse
} = slice.actions;
export default endingInventoryReducer;
