import { useState } from 'react';
import { Request } from 'src/helpers';
import { useNotif } from 'src/hooks';
import { setup } from 'src/lib/endpoints';

/**
 *
 * @param {Request} request
 */
const useTabularLogs = request => {
  const notify = useNotif();
  const [tabularLogs, setTabularLogs] = useState({
    open: false,
    data: []
  });

  /**
   *
   * @param {number} ixSub
   */
  async function viewTabularLogs(ixSub) {
    if (!ixSub) return;

    const res = await request.get(setup.sub.tabularLogs(ixSub));

    if (res.success) {
      setTabularLogs({ open: true, data: res.data });
    } else {
      notify.error(res.errorMessage);
    }
  }

  function closeTabularLogs() {
    setTabularLogs({ open: false, data: [] });
  }

  return { tabularLogs, viewTabularLogs, closeTabularLogs };
};

export default useTabularLogs;
