import React, { useEffect } from 'react';
import { TablePagination } from '@material-ui/core';

/**
 *
 * @param {object} props
 * @param {number} props.totalItems
 * @param {number} props.page
 * @param {(page: number) => void} props.setPage
 * @param {number} props.rowsPerPage
 * @param {(rowsPerPage: number) => void} props.setRowsPerPage
 * @param {number[]} props.rowsPerPageOptions
 * @returns
 */
const TblPagination = ({
  totalItems,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  rowsPerPageOptions
}) => {
  /**
   * Changes the number of rows per page and resets the current page to 0.
   *
   * @param {React.ChangeEvent<HTMLSelectElement>} event - The event that triggered this action.
   */
  function changeRowsPerPage(event) {
    setPage(0);
    setRowsPerPage(event.target.value);
  }

  /**
   * Updates the current page to the specified new page.
   *
   * @param {*} _ - Unused parameter.
   * @param {number} newPage - The page number to navigate to.
   */
  function changePage(_, newPage) {
    setPage(newPage);
  }

  return (
    <TablePagination
      component="div"
      count={totalItems}
      page={page}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
      onPageChange={changePage}
      onRowsPerPageChange={changeRowsPerPage}
      backIconButtonProps={{ disabled: page === 0 }}
      nextIconButtonProps={{
        disabled: (page + 1) * rowsPerPage >= totalItems
      }}
    />
  );
};

export default React.memo(TblPagination);
