import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import JsonEditor from './JsonEditor';
import { isEmpty } from 'lodash';

const useStyles = makeStyles(theme => ({
  textArea: {
    width: '100%',
    padding: theme.spacing(1),
    marginTop: '1em'
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  headerTextMargin: {
    marginLeft: theme.spacing(1),
    fontWeight: 500
  },
  cardContent: {
    padding: theme.spacing(2),
    maxHeight: 600
  }
}));

const showNewlines = text => {
  const toRenderHtml = text.replace(/(\r\n|\n|\r)/gm, ' <br> ');
  return {
    __html: toRenderHtml
  };
};

const ViewLogs = ({
  children,
  title,
  openTransactionDialog,
  setOpenTransactionDialog,
  onLogsClosed = () => {}
}) => {
  const classes = useStyles();

  function handleClose() {
    setOpenTransactionDialog(false);
    onLogsClosed();
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={openTransactionDialog}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box> {title} </Box>
          <Button
            onClick={handleClose}
            color="primary"
            variant="text"
            size="small"
          >
            Close
          </Button>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.cardContent}>{children}</DialogContent>
    </Dialog>
  );
};

const Logitem = ({ data }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const classNames = useStyles();

  const json_data = data?.logs_json;

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <Box p={1} mt={1} mb={1}>
      <Box display="flex" mb={1} alignItems="center">
        <Avatar
          className={classes.avatar}
          src={`/api/images/user/${data.UserID}/avatar.jpg`}
        >
          {data.UserID[0].toUpperCase()}
        </Avatar>
        <Typography variant="caption" className={classes.headerTextMargin}>
          {data.UserID.charAt(0).toUpperCase() + data.UserID.slice(1)}
        </Typography>
        <Typography
          variant="caption"
          display="block"
          style={{ opacity: '0.7', marginLeft: '.5em' }}
        >
          {moment(data.ts).format('lll')}
        </Typography>
      </Box>
      <Paper
        component={Box}
        p={2}
        ml={4}
        fontFamily="fontFamily"
        fontSize={14}
        elevation={3}
      >
        <div dangerouslySetInnerHTML={showNewlines(data.Description)} />
        {!isEmpty(json_data) && !open && (
          <Box mt={2} marginRight={2}>
            <Button
              variant="text"
              color="primary"
              size="small"
              onClick={handleClickOpen}
            >
              More
            </Button>
          </Box>
        )}
        {open && (
          <JsonEditor value={json_data} disabled styles={classNames.textArea} />
        )}
      </Paper>
    </Box>
  );
};

const TransactionLogsTable = ({ data }) => {
  if (data.length === 0) {
    return (
      <Box p={3}>
        <Typography variant="h4" align="center" color="primary">
          No Result Found.
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      {data &&
        data.map((item, i) => (
          <Box key={i}>
            <Box mb={2}>
              <Logitem data={item} />
            </Box>
            {i + 1 !== data.length ? <Divider /> : null}
          </Box>
        ))}
    </Box>
  );
};

const ViewTransactionLogs = ({
  url,
  title,
  method = 'GET',
  payload = null,
  openTransactionDialog,
  setOpenTransactionDialog,
  onLogsClosed = () => {}
}) => {
  const [loading, setLoading] = useState(false);
  const [transactionLogs, setTransactionLogs] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const { base_url, userToken } = useSelector(state => state.auth);
  const json_data = 'json_data';

  useEffect(() => {
    setLoading(true);
    const fetchTransactionLogs = async () => {
      let payloadData = null;

      if (payload) {
        payloadData = {
          data: {
            ...payload
          }
        };
      }

      try {
        const { data } = await axios({
          method,
          url: `${base_url}${url}`,
          headers: {
            'Content-Type': 'application/json',
            'x-access-tokens': userToken
          },
          ...payloadData
        });

        setTransactionLogs(Object.keys(data).length === 0 ? [] : data);
        setLoading(false);
        setError(false);
        setErrorMessage('');
      } catch (error) {
        setError(true);
        setErrorMessage('Something went wrong.');
        setLoading(false);
      }
    };

    if (openTransactionDialog) {
      fetchTransactionLogs();
    }
  }, [openTransactionDialog]);

  return (
    <ViewLogs
      openTransactionDialog={openTransactionDialog}
      setOpenTransactionDialog={setOpenTransactionDialog}
      title={title}
      onLogsClosed={onLogsClosed}
    >
      {loading && (
        <Box p={3} display="flex" justifyContent="center">
          <CircularProgress size={30} />
        </Box>
      )}
      {error && <Alert severity="warning">{errorMessage}</Alert>}
      {!loading && !error && <TransactionLogsTable data={transactionLogs} />}
    </ViewLogs>
  );
};

export default ViewTransactionLogs;
