import React, { ImgHTMLAttributes } from 'react';
import {
  Box,
  Typography,
  useTheme,
  BoxProps,
  TypographyProps
} from '@material-ui/core';

/**
 * EmptyBox is a component that shows an empty state of a container.
 * It renders a box with an empty image, a title and an optional children.
 * @param {Object} props
 * @param {String} [props.title=''] - title of the empty box
 * @param {TypographyProps} [props.titleProps={}] - props for the title
 * @param {BoxProps} [props.containerProps={}] - props for the container
 * @param {ImgHTMLAttributes} [props.imageProps={}] - props for the empty image
 * @param {React.JSX.Element} [props.children=null] - children to be rendered inside the empty box
 * @returns {ReactElement}
 */
const EmptyBox = ({
  title = '',
  titleProps = {},
  containerProps = {},
  imageProps = {},
  children
}) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gridRowGap={theme.spacing(1)}
      {...containerProps}
    >
      <img
        height={150}
        src="/static/images/404.png"
        alt="Empty"
        style={{ marginBottom: 16 }}
        {...imageProps}
        width="auto"
      />
      <Typography variant="h3" color="primary" {...titleProps}>
        {title || 'Empty!'}
      </Typography>
      {children}
    </Box>
  );
};

export default EmptyBox;
