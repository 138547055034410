import { escapeRegExp } from 'lodash';

function filterTableData(data = [], filters = {}, searchFilter = '') {
  const searchRegEx = new RegExp(escapeRegExp(searchFilter), 'i');

  return data.filter(item => {
    for (const key of Object.keys(filters)) {
      if (filters[key] !== 'all' && item[key] !== filters[key]) {
        return false;
      }
    }

    return Object.keys(item).some(field =>
      searchRegEx.test((item?.[field] || '').toString())
    );
  });
}

export default filterTableData;
