import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
const INITIAL_STATE = {
  dateRange: {
    dt1: '',
    dt2: '',
    textValue: ''
  },
  activeJCd: [],
  activeWH_From: {},
  activeWH_To: -1,
  activeProdCat: {},
  activeProdSubCat: {},
  activeBrch: 0,
  showProdDetails: false,
  result: [],
  meta: {
    jcd: [],
    wh: []
  },
  subWarehouse1: {
    show: false,
    ixSubAcc: 0,
    sSubAcc: '',
    ixSubType: 0,
    sSubType: ''
  },
  subWarehouse2: {
    show: false,
    ixSubAcc: 0,
    sSubAcc: '',
    ixSubType: 0,
    sSubType: ''
  },
  loadAll: false,
  size: 50,
  page: 1,
  showSN: false,
  showSN2: false,
  showBN: false,
  showExpDate: false,
  showBrchCol: false,
  showCS: false,
  showSC: false,
  ixProdSubLink1: 0,
  sProdSubLink1: '',
  ixProdSubLink2: 0,
  sProdSubLink2: '',
  ixProdSubLink3: 0,
  sProdSubLink3: '',
  ixProdSubLink4: 0,
  sProdSubLink4: '',
  ixProdSubLink5: 0,
  sProdSubLink5: ''
};

const slice = createSlice({
  name: 'whTransfer',
  initialState: INITIAL_STATE,
  reducers: {
    storeDateRange(state, action) {
      state.dateRange = action.payload;
    },
    storeActiveJCd(state, action) {
      state.activeJCd = action.payload;
    },
    storeActiveWHFrom(state, action) {
      state.activeWH_From = action.payload;
    },
    storeActiveWHTo(state, action) {
      state.activeWH_To = action.payload;
    },
    storeSubWarehouse(state, action) {
      state.subWarehouse1 = action.payload;
    },
    storeSubWarehouse2(state, action) {
      state.subWarehouse2 = action.payload;
    },
    storeActiveProdCat(state, action) {
      state.activeProdCat = action.payload;
    },
    storeActiveProdSubCat(state, action) {
      state.activeProdSubCat = action.payload;
    },
    storeActiveBranch(state, action) {
      state.activeBrch = action.payload;
    },
    storeShowProdDetails(state, action) {
      state.showProdDetails = action.payload;
    },
    storeResult(state, action) {
      const items = action?.payload?.data ?? [];
      const loadAll = action?.payload?.loadAll ?? false;

      state.result = items.map(item => ({ ...item, key_id: uuid() }));
      state.showCS = items.some(item => item.libQtyCS > 0);
      state.showSC = items.some(item => item.libQtySC > 0);
      state.showSN = items.some(item => item.SN !== '');
      state.showSN2 = items.some(item => item.SN2 !== '');
      state.showBrchCol = items.some(
        (item, _, arr) => item.ixBrch !== (arr?.[0]?.ixBrch ?? 0)
      );
      state.showBN = items.some(item => item.BN !== '');
      state.showExpDate = items.some(item => item.ExpDate !== '');
      state.loadAll = loadAll;

      if (loadAll) {
        state.size = 50;
        state.page = 1;
      }
    },
    clearData(state, action) {
      state.result = [];
    },
    setMeta(state, action) {
      state.meta = action.payload;
    },
    setProdSubLink1(state, action) {
      state.ixProdSubLink1 = action.payload.ixProdSubLink1;
      state.sProdSubLink1 = action.payload.sProdSubLink1;
    },
    setProdSubLink2(state, action) {
      state.ixProdSubLink2 = action.payload.ixProdSubLink2;
      state.sProdSubLink2 = action.payload.sProdSubLink2;
    },
    setProdSubLink3(state, action) {
      state.ixProdSubLink3 = action.payload.ixProdSubLink3;
      state.sProdSubLink3 = action.payload.sProdSubLink3;
    },
    setProdSubLink4(state, action) {
      state.ixProdSubLink4 = action.payload.ixProdSubLink4;
      state.sProdSubLink4 = action.payload.sProdSubLink4;
    },
    setProdSubLink5(state, action) {
      state.ixProdSubLink5 = action.payload.ixProdSubLink5;
      state.sProdSubLink5 = action.payload.sProdSubLink5;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setSize(state, action) {
      state.size = action.payload;
      state.page = 1;
    },
    resetState() {
      return INITIAL_STATE;
    },
    clear() {
      return INITIAL_STATE;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    }
  }
});

const whTransferReducer = slice.reducer;

export const {
  storeDateRange,
  storeActiveJCd,
  storeActiveWHFrom,
  storeActiveWHTo,
  storeActiveVariation,
  storeActiveProdCat,
  storeActiveProdSubCat,
  storeActiveBranch,
  storeShowProdDetails,
  storeSubWarehouse,
  storeSubWarehouse2,
  setMeta,
  setProdSubLink1,
  setProdSubLink2,
  setProdSubLink3,
  setProdSubLink4,
  setProdSubLink5,
  clearData,
  storeResult,
  setPage,
  setSize,
  resetState,
  restore,
  clear
} = slice.actions;
export default whTransferReducer;
