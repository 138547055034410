/**
 * @param {object[]} data
 * @param {number} page
 * @param {number} rowsPerPage
 * @returns {object[]}
 * @description
 * Returns an array of objects containing the data for the specified page.
 * The start index is calculated as (page - 1) * rowsPerPage.
 * The end index is calculated as start index + rowsPerPage.
 * The returned array will contain the data for the specified page.
 * If the page is out of range, or if the rowsPerPage is 0,
 * an empty array will be returned.
 */
function getPageData(data, page, rowsPerPage) {
  const lastItem = (page + 1) * rowsPerPage;

  return data.slice(lastItem - rowsPerPage, lastItem);
}

export default getPageData;
