import React from 'react';
import {
  Dialog,
  LinearProgress,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Divider,
  Paper,
  IconButton,
  Avatar,
  makeStyles
} from '@material-ui/core';
import moment from 'moment';
import { Close } from '@material-ui/icons';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5)
  },
  headerTextMargin: {
    fontWeight: 500
  }
}));

/**
 * @typedef {{date:string,ixUser:number,items:Array<[string, [string, string]]>}} log
 */

/**
 * @param {object} props
 * @param {{key:number,value:log}} props.log
 */
const Card = ({ log }) => {
  const classes = useStyles();
  const { users = [] } = useSelector(state => state.bizMeta);
  const user = users.find(user => user.ixUser === log.value.ixUser);

  return (
    <Box
      component={Paper}
      p={1}
      display="flex"
      flexDirection="column"
      gridGap={1}
    >
      <Box display="flex" gridGap={2} alignItems="center">
        <Avatar
          className={classes.avatar}
          src={user ? `/api/images/user/${user.username}/avatar.jpg` : null}
        >
          {user ? user.FName[0].toUpperCase() : null}
        </Avatar>
        <Box display="flex" flexDirection="column" ml={2}>
          {Boolean(user) && (
            <Typography variant="caption" className={classes.headerTextMargin}>
              {user.FName + ' ' + user.LName}
            </Typography>
          )}
          <Typography variant="caption" color="textSecondary">
            {moment(log.date).format('MMM DD, YYYY h:mm a')}
          </Typography>
        </Box>
      </Box>
      <Box py={1}>
        <Divider />
      </Box>
      {log.value.items.map(logItem => (
        <Box key={logItem[0]} mt={1}>
          <Typography variant="caption" color="textSecondary">
            {logItem[0]}
          </Typography>
          <Typography variant="body2">
            {`${logItem[1][0]} -> ${logItem[1][1]}`}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

/**
 * @param {object} props
 * @param {Array<log>} props.data
 * @param {boolean} open
 * @param {boolean} isLoading
 * @param {VoidFunction} onClose
 */
const TabularLogs = ({
  data = [],
  open = false,
  isLoading = false,
  onClose = () => {}
}) => {
  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle disableTypography>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4">Logs</Typography>
          <IconButton onClick={onClose} size="small">
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      {isLoading && <LinearProgress />}
      <DialogContent style={{ padding: 16 }}>
        <Box display="flex" flexDirection="column" gridGap={2}>
          {data.map(dataItem => (
            <Card key={dataItem.date} log={dataItem} />
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default TabularLogs;
