import React, { useEffect, useState } from 'react';
import {
  Box,
  DialogActions,
  Divider,
  Button,
  makeStyles,
  Typography,
  useTheme
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { getDateValues } from './utils';
import moment from 'moment';

const useStyles = makeStyles({
  year: {
    '& .MuiPickersBasePicker-pickerView': {
      width: '100%',
      maxWidth: 'none',
      minWidth: 0,
      '& .MuiPickersYearSelection-container': {
        padding: '0 1em',
        display: 'flex',
        gap: '2em 4em',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
        alignItems: 'stretch',
        overflowY: 'initial',
        '& .MuiPickersYear-yearSelected': {
          margin: 0,
          fontWeight: 700
        }
      },
      '& .MuiPickersMonthSelection-container': {
        width: '100%'
      }
    },
    '& .MuiToolbar-root': {
      width: '180px'
    }
  },
  quarterBox: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#E9EBF6',
    cursor: 'pointer'
  }
});

const quarters = [
  { quarter: 1, caption: '1st Quarter' },
  { quarter: 2, caption: '2nd Quarter' },
  { quarter: 3, caption: '3rd Quarter' },
  { quarter: 4, caption: '4th Quarter' }
];

const Quarterly = ({
  handleChangeDatePickerValue,
  handleCloseDialog,
  dateValues,
  prefixText
}) => {
  const theme = useTheme();
  const classNames = useStyles();
  const [quarter, setQuarter] = useState(0);
  const [year, setYear] = useState(moment().year());

  const handleOk = () => {
    handleChangeDatePickerValue(
      getDateValues.quarter({
        values: {
          dt1: moment()
            .year(year)
            .quarter(quarter)
            .startOf('quarter')
            .format('YYYY-MM-DD'),
          dt2: moment()
            .year(year)
            .quarter(quarter)
            .endOf('quarter')
            .format('YYYY-MM-DD')
        },
        prefixText: prefixText || 'quarter'
      })
    );
    handleCloseDialog();
  };

  const handleClose = () => {
    handleCloseDialog();
  };

  const updateYear = val => {
    setYear(moment(val).year());
  };

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          className={classNames.year}
        >
          <Box
            display="flex"
            justifyContent="space-evenly"
            alignItems="stretch"
            height="60px"
            bgcolor={theme.palette.primary.main}
          >
            {quarters.map(item => (
              <Box
                className={classNames.quarterBox}
                onClick={() => setQuarter(item.quarter)}
              >
                <Typography
                  variant="h6"
                  className={classNames.quarterText}
                  style={{
                    borderBottom:
                      quarter === item.quarter ? '2px solid #E9EBF6' : 'none'
                  }}
                >
                  {item.caption}
                </Typography>
              </Box>
            ))}
          </Box>
          <DatePicker
            variant="static"
            orientation="landscape"
            views={['year']}
            label="Select Year"
            value={moment()
              .year(year)
              .format()}
            onChange={updateYear}
            inputVariant="outlined"
            allowKeyboardControl
            autoOk={true}
          />
        </Box>
        <Divider />
        <DialogActions style={{ justifyContent: 'space-between' }}>
          <Button color="primary" onClick={handleClose}>
            Cancel
          </Button>

          <Button color="primary" variant="contained" onClick={handleOk}>
            Ok
          </Button>
        </DialogActions>
      </MuiPickersUtilsProvider>
    </>
  );
};

export default Quarterly;
