import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { makeStyles, ThemeProvider } from '@material-ui/core';
import TopBar from './TopBar';
import Loading from 'src/components/Loading';
import { NAVBAR_HEIGHT } from 'src/constants';
import { useEnv, useToggle } from 'src/hooks';
import SideBar from './SideBar';
import { createTheme } from 'src/theme';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: NAVBAR_HEIGHT
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const PortalLayout = () => {
  const classes = useStyles();
  const [isSideBarOpen, , , toggleSideBar] = useToggle();

  const { EGOV } = useEnv();

  const theme = createTheme({
    direction: 'ltr',
    responsiveFontSizes: true,
    theme: 'DEFAULT',
    mode: 'light',
    EGOV
  });

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <TopBar toggleSideBar={toggleSideBar} />
        <SideBar isCollapsed={isSideBarOpen} toggleSideBar={toggleSideBar} />
        <div className={classes.wrapper}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>
              <Suspense fallback={<Loading />}>
                <Outlet />
              </Suspense>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default PortalLayout;
