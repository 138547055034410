import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  LinearProgress,
  makeStyles,
  Typography,
  withStyles
} from '@material-ui/core';
import { useNotif, useRequest, useToggle } from 'src/hooks';
import { Request } from 'src/helpers';
import { orange } from '@material-ui/core/colors';
import { DebounceTextField, Field, Loading } from 'src/components';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { isEmpty, sortBy } from 'lodash';

const useStyles = makeStyles(theme => ({
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100vh'
  },
  container: {
    marginTop: theme.spacing(3)
  },
  title: {
    fontSize: '3rem',
    fontWeight: 900
  }
}));

const ColorButton = withStyles(theme => ({
  root: {
    color: '#fff',
    backgroundColor: orange[500],
    '&:hover': {
      backgroundColor: orange[300]
    }
  }
}))(Button);

const GuestPortal = () => {
  const [sourceParams] = useSearchParams();
  const classes = useStyles();
  const { error, success } = useNotif();
  const { ixJCd, cdBiz, cdPortal } = useParams();
  const [guestTransaction, setGuestTransaction] = useState();
  const [guestTransactionMeta, setGuestTransactionMeta] = useState();
  const [isLoading, loadingOn, loadingOff] = useToggle();
  const [isSubmitted, submittedOn, submittedOff] = useToggle();
  const req = useRequest(loadingOn, loadingOff);
  const [formData, setFormData] = useState({});

  const isVisible = fld => guestTransactionMeta?.flds?.includes(fld);

  const fetch = async cancelCallback => {
    const jcdMetaRes = await req.get(
      `/portal/guest/${cdBiz}/${cdPortal}/meta`,
      {},
      cancelCallback
    );
    const transMetaRes = await req.post(
      `/portal/guest/${cdBiz}/${cdPortal}/trans/${jcdMetaRes.data
        ?.transactions[0]?.ixJCd || ixJCd}/meta`,
      {},
      {},
      cancelCallback
    );

    if (jcdMetaRes.isCancelled || transMetaRes.isCancelled) return;
    if (!jcdMetaRes.success || !transMetaRes.success) {
      error(jcdMetaRes.errorMessage);
      error(transMetaRes.errorMessage);
      return;
    }

    setGuestTransaction(jcdMetaRes.data.transactions[0]);
    setGuestTransactionMeta(transMetaRes.data);
  };

  const onChangeField = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const onChangeKVS = e => {
    setFormData({
      ...formData,
      kvs: {
        ...formData.kvs,
        [e.target.name]: e.target.value
      }
    });
  };

  const isFieldFalsy = field => {
    if (isSubmitted) {
      return !formData[field] || formData[field].length === 0;
    }
  };

  const isKVSFieldFalsy = field => {
    if (isSubmitted) {
      if (guestTransactionMeta?.kvs[field]?.required) {
        return !formData?.kvs?.[field] || formData?.kvs?.[field].length === 0;
      }
    }
  };

  const onSubmit = async () => {
    submittedOn();
    if (isEmpty(formData['Name']))
      return error(
        `${guestTransactionMeta?.labels?.Name ?? 'Name'} is required`
      );

    if (!isEmpty(guestTransactionMeta?.kvs)) {
      for (const key in guestTransactionMeta.kvs) {
        if (
          guestTransactionMeta.kvs[key].required &&
          isEmpty(formData?.kvs?.[key])
        ) {
          return error(
            `${guestTransactionMeta?.kvs[key]?.label ??
              guestTransactionMeta?.kvs[key]?.caption ??
              key} is required`
          );
        }
      }
    }

    if (isEmpty(formData['Particulars']))
      return error(
        `${guestTransactionMeta?.labels?.Particulars ??
          'Particulars'} is required`
      );

    const payload = {
      ...formData
    };

    if (!isEmpty(payload.kvs)) {
      payload.kvs = formData.kvs;
    }

    const res = await req.post(
      `/portal/guest/${cdBiz}/${cdPortal}/trans/${guestTransaction?.ixJCd}/save`,
      payload
    );

    if (!res.success) {
      submittedOff();
      return error(res.errorMessage);
    }

    success('Success');
    setFormData({});
    submittedOff();
  };

  useEffect(() => {
    let cancel = () => {};
    fetch(c => (cancel = c));
    return cancel;
  }, []);

  if (isLoading) return <Loading />;

  return (
    <Container className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box borderLeft={5} borderColor="primary.main" pl={1} my={2}>
                    <Typography variant="h1" color="textSecondary">
                      {(guestTransaction?.label_singular ||
                        guestTransactionMeta?.label) ??
                        'Message Us'}
                    </Typography>
                  </Box>
                </Grid>
                {isVisible('Name') && (
                  <Grid item xs={12}>
                    <Field
                      onChangeField={onChangeField}
                      data={{
                        disabled: isLoading,
                        caption: guestTransactionMeta?.labels?.Name ?? 'Name',
                        value: formData['Name'],
                        fld: 'Name',
                        type: 'str',
                        error: isFieldFalsy('Name')
                      }}
                    />
                  </Grid>
                )}

                {sortBy(
                  Object.entries(guestTransactionMeta?.kvs || {}),
                  ([, props]) => props.sort
                ).map(([key, props]) => (
                  <Grid item xs={12} key={key}>
                    <Field
                      onChangeField={onChangeKVS}
                      data={{
                        disabled: isLoading,
                        caption: guestTransactionMeta?.kvs[key]?.label ?? key,
                        value: formData?.kvs?.[key] ?? '',
                        type: 'str',
                        fld: key,
                        error: isKVSFieldFalsy(key),
                        ...props
                      }}
                    />
                  </Grid>
                ))}

                {isVisible('Particulars') && (
                  <Grid item xs={12}>
                    <Field
                      onChangeField={onChangeField}
                      data={{
                        disabled: isLoading,
                        caption:
                          guestTransactionMeta?.labels?.Particulars ??
                          'Particulars',
                        value: formData['Particulars'],
                        fld: 'Particulars',
                        type: 'str',
                        isLongText: true,
                        error: isFieldFalsy('Particulars')
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={3}>
                  <ColorButton
                    type="submit"
                    disabled={isLoading}
                    size="large"
                    fullWidth
                    onClick={onSubmit}
                    endIcon={
                      isLoading ? (
                        <CircularProgress size={15} color="secondary" />
                      ) : null
                    }
                  >
                    Submit
                  </ColorButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default GuestPortal;
