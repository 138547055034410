function getCellStyles(col, left, extraStyles = {}) {
  return {
    width: col?.width || 200,
    minWidth: col?.width ? col.width : col?.minWidth || 200,
    position: col?.sticky ? 'sticky' : 'static',
    zIndex: col?.sticky ? 15 : 10,
    left,
    ...extraStyles
  };
}

export default getCellStyles;
