import React from 'react';
import { useRequest, useToggle } from 'src/hooks';
import { SILLedger, TransView, TabularLogs } from './components';
import { useSilLedger, useTabularLogs, useTransView } from './hooks';

/**
 * @typedef {Object} Context
 * @property {(props: {ixEmp: number, ixSILType: number}) => void} viewSILLedger
 * @property {(jid: number|string) => void} viewTransDetails
 * @property {(ixSub: number) => void} viewTabularLogs
 */

/**
 * @type {React.Context<Context>}
 */
const GlobalComponentsContext = React.createContext();

const GlobalComponentsProvider = ({ children }) => {
  const [isLoading, loadingOn, loadingOff] = useToggle();

  const request = useRequest(loadingOn, loadingOff);

  const {
    viewSILLedger,
    ledgerProps,
    ledgerData,
    closeSilLedger,
    changeIxSILType
  } = useSilLedger(request);
  const { viewTransDetails, transViewProps, closeTransView } = useTransView(
    request
  );
  const { tabularLogs, viewTabularLogs, closeTabularLogs } = useTabularLogs(
    request
  );

  return (
    <GlobalComponentsContext.Provider
      value={{ viewSILLedger, viewTransDetails, viewTabularLogs }}
    >
      <SILLedger
        open={ledgerProps.open}
        onClose={closeSilLedger}
        changeIxSILType={changeIxSILType}
        data={ledgerData}
        ixSILType={ledgerProps.ixSILType}
        isLoading={isLoading}
      />
      <TransView
        open={transViewProps.open}
        onClose={closeTransView}
        isLoading={isLoading}
        transDetails={transViewProps.details}
      />
      <TabularLogs
        open={tabularLogs.open}
        data={tabularLogs.data}
        isLoading={isLoading}
        onClose={closeTabularLogs}
      />
      {children}
    </GlobalComponentsContext.Provider>
  );
};

export default GlobalComponentsContext;
export { GlobalComponentsProvider };
