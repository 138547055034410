import { useState, useEffect } from 'react';
import { trans } from 'src/lib/endpoints';
import { LEAVES_W_BAL } from 'src/constants';
import moment from 'moment';
import { useNotif } from 'src/hooks';
import { Request } from 'src/helpers';

/**
 *
 * @param {Request} request
 * @returns
 */
const useSilLedger = request => {
  const notify = useNotif();

  /**
   * @type {state<Array<{
   *   ixSILType: number,
   *   ixEmp: number,
   *   jid: number,
   *   jStatus: 0|1|2|3,
   *   Name: string,
   *   jDate: string,
   *   RefNo: string,
   *   Particulars: string,
   *   Remarks: string,
   *   ixDate: number,
   *   ixTime1: number,
   *   ixTime2: number,
   *   qtyIN: number,
   *   qtyOUT: number,
   *   ixPR: number,
   *   sPR: string,
   *   t.Remarks: string,
   *   bal: number
   * }>>}
   */
  const [ledgerData, setLedgerData] = useState([]);
  const [ledgerProps, setLedgerProps] = useState({
    ixEmp: 0,
    ixSILType: 0,
    ixDate: 0,
    open: false
  });

  /**
   *
   * @param {cancelCallBack} cancelCallback
   */
  async function getSILLedger(cancelCallback) {
    const res = await request.post(
      trans.silLedger,
      {
        ixEmp: ledgerProps.ixEmp,
        ixSILType: ledgerProps.ixSILType,
        ixDate: ledgerProps.ixDate
      },
      cancelCallback
    );

    if (res.success) {
      setLedgerData(res.data);
    } else if (!res.isCancelled) {
      notify.error(res.errorMessage);
    }
  }

  /**
   *
   * @param {object} props
   * @param {number} props.ixEmp
   * @param {number} props.ixSILType
   */
  function viewSILLedger({ ixEmp, ixSILType }) {
    if (!ixEmp) {
      notify.error('Invalid employee ID');
    }

    setLedgerProps({
      ixEmp,
      ixSILType: LEAVES_W_BAL.includes(ixSILType) ? ixSILType : 1,
      ixDate: parseInt(moment().format('YYYYMMDD')),
      open: true
    });
  }

  function closeSilLedger() {
    setLedgerProps({
      ixEmp: 0,
      ixSILType: 0,
      ixDate: 0,
      open: false
    });
  }

  function changeIxSILType(ixSILType) {
    setLedgerProps(state => ({
      ...state,
      ixSILType
    }));
  }

  useEffect(() => {
    if (
      !ledgerProps.open ||
      !ledgerProps.ixEmp ||
      !ledgerProps.ixSILType ||
      !ledgerProps.ixDate
    )
      return;

    let cancel = () => {};
    getSILLedger(c => (cancel = c));
    return cancel;
  }, [ledgerProps]);

  return {
    viewSILLedger,
    ledgerProps,
    ledgerData,
    closeSilLedger,
    changeIxSILType
  };
};

export default useSilLedger;
