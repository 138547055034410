import React from 'react';
import { Navigate } from 'react-router-dom';
import LandingPageLayout from 'src/layouts/LandingPageLayout';
import UlapBiz from 'src/views/landingPage/ulapBiz';
import SubSystems from 'src/views/landingPage/SubSystems';
import PlanDetails from 'src/views/landingPage/PlanDetails';
import ContactUs from 'src/views/landingPage/ContactUs/ContactUs';
import SupportPage from 'src/views/landingPage/Support';
import MotorcycleDealers from 'src/views/landingPage/Solutions/MotorcycleDealers';
import UlapTax from 'src/views/landingPage/ulapTax';
import Construction from 'src/views/landingPage/Solutions/Construction';
import NotFoundView from 'src/views/errors/NotFoundView';
import DynamicPage from 'src/views/landingPage/DynamicPage';

const ulapBizRoutes = [
  {
    path: '/oursocials',
    element: Navigate,
    elementProps: { to: '/pages/oursocials' }
  },
  {
    path: '',
    element: LandingPageLayout,
    children: [
      { path: '/', element: UlapBiz },
      { path: 'support', element: SupportPage },
      { path: 'tax', element: UlapTax },
      { path: 'contact-us', element: ContactUs },
      { path: 'plans', element: UlapBiz },
      { path: 'plans-details', element: PlanDetails },
      { path: 'reports-and-schedule', element: UlapBiz },
      { path: 'notable-features', element: UlapBiz },
      { path: 'beyond-accounting/:key', element: SubSystems },
      { path: '/pages/:cdPage', element: DynamicPage },
      {
        path: 'solutions',
        children: [
          { path: 'motorcycle-dealers', element: MotorcycleDealers },
          {
            path: 'construction',
            element: Construction
          }
        ]
      },
      { path: 'accounting-and-beyond', element: UlapBiz }
    ]
  },
  {
    path: '*',
    element: NotFoundView
  }
];

export default ulapBizRoutes;
