import { createSlice } from '@reduxjs/toolkit';
import { cancelAPIRequest, APIRequestV2 } from 'src/helpers/APIRequest';

const INITIAL_STATE = {
  loading: false,
  accountList: null,
  selectedAccount: '',
  bankIxSubType: 0,
  sSubType: '',
  selectedBank: {
    sub_id: null,
    sub_title: ''
  },
  formFilterData: {
    ixAcc: null,
    ixSub: 0,
    dt1: null,
    dt2: null,
    textValue: ''
  },
  brch: {
    required: false,
    ixBrch: 0,
    label: ''
  },
  checkNumberSearchValue: '',
  checkAmountSearchValue: '',
  matureChecksList: null,
  page: 0,
  limit: 25,
  errorStatus: false,
  errorMessage: ''
};

const slice = createSlice({
  name: 'matureChecks',
  initialState: INITIAL_STATE,
  reducers: {
    onFetchAccountList(state, action) {
      state.loading = true;
    },
    loadAccounts(state, action) {
      state.accountList = action.payload;
      state.loading = false;
    },
    loadError(state, action) {
      state.loading = false;
      state.errorStatus = true;
      state.errorMessage = action.payload.message;
    },
    clearError(state, action) {
      state.errorStatus = false;
      state.errorMessage = '';
    },
    resetReport(state, action) {
      return { ...INITIAL_STATE, accountList: state.accountList };
    },
    selectAccount(state, action) {
      state.selectedAccount = action.payload.ixAcc;
      state.formFilterData.ixAcc = action.payload.ixAcc;
      state.bankIxSubType = action.payload.ixSubType;
      state.sSubType = action.payload.sSubType;
    },
    setDateRange(state, action) {
      state.formFilterData.dt1 = action.payload.dt1;
      state.formFilterData.dt2 = action.payload.dt2;
      state.formFilterData.textValue = action.payload.textValue;
    },
    clearData(state, action) {
      state.matureChecksList = null;
      state.checkNumberSearchValue = '';
      state.checkAmountSearchValue = '';
    },
    onFetchReport(state, action) {
      state.loading = true;
      state.errorStatus = false;
      state.errorMessage = '';
    },
    loadData(state, action) {
      state.matureChecksList = action.payload;
      state.loading = false;
    },
    setLimit(state, action) {
      state.limit = action.payload;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setCheckNumberSearchValue(state, action) {
      state.checkNumberSearchValue = action.payload;
      state.page = 0;
    },
    setCheckAmountSearchValue(state, action) {
      state.checkAmountSearchValue = action.payload;
      state.page = 0;
    },
    setMatureChecksBank(state, action) {
      state.selectedBank.sub_id = action.payload.sub_id;
      state.selectedBank.sub_title = action.payload.sub_title;
      state.formFilterData.ixSub = action.payload.sub_id;
    },
    setBrch(state, action) {
      state.brch.label = action.payload.label;
      state.brch.required = action.payload.required;
    },
    changeBrch(state, action) {
      state.brch.ixBrch = action.payload;
    },
    cancelRequest(state, action) {
      state.loading = false;
    },
    clear(state, action) {
      return INITIAL_STATE;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    }
  }
});

export const fetchAccounts = () => async (dispatch, getState) => {
  const { base_url, userToken } = getState().auth;
  const { onFetchAccountList, loadAccounts, loadError } = slice.actions;
  dispatch(onFetchAccountList());

  const { success, data } = await APIRequestV2({
    url: `${base_url}/reports/mature/checks/init`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'x-access-tokens': userToken
    }
  });

  if (!success) {
    loadError({
      message: `Error - Something went wrong.`
    });
    return;
  }

  dispatch(loadAccounts(data));
};

export const fetchReport = () => async (dispatch, getState) => {
  const { formFilterData, brch } = getState().matureChecks;
  const { base_url, userToken } = getState().auth;
  const { clearData, clearError, onFetchReport, loadError } = slice.actions;

  if (formFilterData.ixAcc === null) {
    dispatch(loadError({ message: 'Please select an account.' }));
    return;
  }

  if (formFilterData.ixSub === null) {
    dispatch(loadError({ message: 'Please select bank.' }));
    return;
  }

  if (brch.required && brch.ixBrch === '') {
    dispatch(loadError({ message: `${brch.label} is required.` }));
    return;
  }

  if (formFilterData.dt1 === null || formFilterData.dt2 === null) {
    dispatch(
      loadError({
        message: 'Start date or End date is empthy.'
      })
    );
    return;
  }

  const dt1 = new Date(formFilterData.dt1).getTime();
  const dt2 = new Date(formFilterData.dt2).getTime();

  if (dt1 > dt2) {
    dispatch(
      loadError({
        message: 'Start date cannot be greater than end date'
      })
    );
    return;
  }

  dispatch(clearData());
  dispatch(clearError());
  dispatch(onFetchReport());

  let payload = formFilterData;

  if (brch?.required) {
    payload = {
      ...payload,
      ixBrch: brch.ixBrch
    };
  }

  const { success, data, isCancelled, error } = await APIRequestV2({
    method: 'POST',
    url: `${base_url}/reports/mature/checks`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-tokens': userToken
    },
    data: payload
  });

  if (!success && isCancelled) return;
  else if (!success) {
    dispatch(
      loadError({
        message: error?.data?.message ?? error?.data?.msg ?? error?.data
      })
    );
  } else {
    dispatch(loadData(data));
  }
};

export const cancelRequest = () => (dispatch, getState) => {
  const action = slice.actions;

  if (cancelAPIRequest) cancelAPIRequest();

  dispatch(action.cancelRequest());
};

const matureChecksReducer = slice.reducer;

export const {
  onFetchAccountList,
  loadAccounts,
  loadError,
  clearError,
  resetReport,
  selectAccount,
  setDateRange,
  clearData,
  onFetchReport,
  loadData,
  setLimit,
  setPage,
  setCheckNumberSearchValue,
  setCheckAmountSearchValue,
  setMatureChecksBank,
  setBrch,
  changeBrch,
  restore,
  clear
} = slice.actions;
export default matureChecksReducer;
