import React from 'react';
import { Box, Button, Typography, Paper } from '@material-ui/core';
import { createBrowserHistory } from 'history';

export const errorCodeImgs = {
  '401': '/static/images/403.png',
  '403': '/static/images/403.png',
  '404': '/static/images/404.png',
  '500': '/static/images/403.png'
};

export const ErrorView = ({
  errorMsg,
  errorCode,
  backfunc = null,
  btnCaption = 'Back'
}) => {
  const back = () => {
    if (backfunc) {
      backfunc();
      return;
    }
    createBrowserHistory().go(-1);
  };

  return (
    <Box
      height="100%"
      width="100%"
      p={3}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Paper
        component={Box}
        p={6}
        elevation={3}
        display="flex"
        justifyContent="center"
        flexDirection="column"
        width={{ xs: '100%', sm: '100%', md: '60%', lg: '50%', xl: '40%' }}
      >
        <Box mb={2} display="flex" justifyContent="center">
          <img
            src={errorCodeImgs?.[errorCode] ?? '/static/images/403.png'}
            alt={errorMsg}
            width={300}
            height="auto"
          ></img>
        </Box>

        <Typography variant="h1" color="primary" align="center">
          {errorCode}
        </Typography>
        <Typography variant="h5" align="center" style={{ marginBottom: '1em' }}>
          {errorMsg}
        </Typography>

        <Button color="primary" variant="contained" onClick={back}>
          {btnCaption}
        </Button>
      </Paper>
    </Box>
  );
};
