import React from 'react';
import {
  Dialog,
  Box,
  CircularProgress,
  Button,
  useTheme
} from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';
import PerfectScrollbars from 'react-perfect-scrollbar';
import JVDetails from './JVDetails';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

/**
 *
 * @param {object} props
 * @param {boolean} props.open
 * @param {VoidFunction} props.onClose
 * @param {boolean} props.isLoading
 * @param {object} props.transDetails
 * @returns
 */
const TransView = ({
  open = false,
  onClose = () => {},
  isLoading = false,
  transDetails = {}
}) => {
  const theme = useTheme();
  const { biz = {} } = useSelector(state => state.auth);

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={onClose}>
      <Box p={2}>
        <Box>
          <PerfectScrollbars>
            {isLoading ? (
              <Box
                height={400}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress size={40} />
              </Box>
            ) : (
              <>
                {!isEmpty(transDetails) && (
                  <Box>
                    <JVDetails
                      jv={transDetails}
                      wf={transDetails?._rm?.jsonWF?.wf}
                      biz={biz}
                      wfStatus={transDetails?.wfStatus}
                    />
                  </Box>
                )}
              </>
            )}
          </PerfectScrollbars>
        </Box>
        <Box
          width="100%"
          display="flex"
          justifyContent="flex-end"
          style={{ columnGap: theme.spacing(1) }}
        >
          <Button color="primary" onClick={onClose}>
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            href={'/app/trans/' + transDetails.jid}
            target="_blank"
            endIcon={<OpenInNew />}
          >
            View
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default React.memo(TransView);
