import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { v4 as uuid } from 'uuid';
import SingleDatePicker from '../SingleDatePicker';
import moment from 'moment';

const getDefaultValue = type => {
  if (type === 'date') return moment().format('YYYY-MM-DD');
  return '';
};

const TableView = ({ data, onChangeField }) => {
  const value = data?.value || [];
  const columns = data?.columns || [];

  const getValue = () => {
    if (typeof data?.value === 'string') return [];

    return value;
  };

  const handleAddItem = () => {
    const items = [...getValue()];
    const row = {
      id: uuid()
    };

    for (const column of columns) {
      row[column.field] = getDefaultValue(column?.type);
    }

    items.push(row);

    onChangeField({
      target: {
        name: data.fld,
        value: items
      }
    });
  };

  const handleEditItem = row => {
    const items = [...getValue()];
    const index = value.findIndex(item => item.id === row.id);

    items[index][row.field] = row.value;

    onChangeField({
      target: {
        name: data.fld,
        value: items
      }
    });
  };

  const handleDeleteItem = id => {
    const items = [...getValue()];
    onChangeField({
      target: {
        name: data.fld,
        value: items.filter(item => item.id !== id)
      }
    });
  };

  const getColumns = () => {
    const copyColumns = [...columns].map(item => {
      if (item?.type === 'date') {
        return {
          ...item,
          renderCell: ({ row }) => {
            const onChangeField = ({ target: { value, name } }) => {
              handleEditItem({
                id: row.id,
                field: name,
                value
              });
            };

            return (
              <SingleDatePicker
                value={row[item.field]}
                name={item.field}
                onChange={onChangeField}
                fullWidth
              />
            );
          }
        };
      } else return item;
    });

    copyColumns.push({
      field: 'action',
      headerName: 'Action',
      width: 150,
      align: 'right',
      renderCell: ({ row }) => (
        <Button
          onClick={() => handleDeleteItem(row.id)}
          size="small"
          color="primary"
        >
          Delete
        </Button>
      )
    });

    return copyColumns;
  };

  return (
    <Box>
      {Boolean(data?.caption) && (
        <Box mb={1}>
          <Typography variant="h4">{data?.caption ?? 'Table'}</Typography>
        </Box>
      )}
      <DataGrid
        getRowId={row => row.id}
        columns={getColumns()}
        rows={getValue()}
        autoHeight
        onCellEditCommit={handleEditItem}
        {...(data?.tableProps || {})}
      />
      <Box mt={2}>
        <Button
          color="primary"
          size="small"
          variant="contained"
          onClick={handleAddItem}
        >
          Add Item
        </Button>
      </Box>
    </Box>
  );
};

export default TableView;
