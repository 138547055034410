/**
 * Creates a deep copy of an object and sets a value at a given key path.
 *
 * @param {object} obj - The original object to be copied and updated.
 * @param {string} keys - The key path to traverse and set the value.
 * @param {*} value - The value to set at the given key path.
 *
 * @returns {object} The copied object with the updated value.
 */
function setObjectValue({
  keys: keyPath = '',
  value: newValue = '',
  obj: originalObject = {}
}) {
  const objectCopy = { ...originalObject };
  let currentObject = objectCopy;

  const keyPathArray = keyPath.split('.');
  const lastIndex = keyPathArray.length - 1;

  for (const [index, key] of keyPathArray.entries()) {
    if (!currentObject.hasOwnProperty(key) && index !== lastIndex) {
      currentObject[key] = {};
      currentObject = currentObject[key];
    } else if (index === lastIndex) {
      currentObject[key] = newValue;
    } else {
      currentObject = currentObject[key];
    }
  }

  return objectCopy;
}

export default setObjectValue;
