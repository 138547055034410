import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  dateRange: {
    dt1: '',
    dt2: '',
    textValue: ''
  },
  loadAll: false,
  page: 1,
  size: 100,
  activeJCd: [],
  activeWh: [],
  activeProdCat: {},
  activeProdSubCat: {},
  activeBrch: 0,
  showProdDetails: false,
  ixSubType: 0,
  ixSub1: 0,
  sSub1: '',
  ixSub2: 0,
  sSub2: '',
  ixSub3: 0,
  sSub3: '',
  ixSub4: 0,
  sSub4: '',
  ixSub5: 0,
  sSub5: '',
  result: [],
  ixProdSubLink1: 0,
  sProdSubLink1: '',
  ixProdSubLink2: 0,
  sProdSubLink2: '',
  ixProdSubLink3: 0,
  sProdSubLink3: '',
  ixProdSubLink4: 0,
  sProdSubLink4: '',
  ixProdSubLink5: 0,
  sProdSubLink5: '',
  showCS: false,
  showSC: false,
  subWarehouse: {
    show: false,
    ixSubAcc: 0,
    sSubAcc: '',
    ixSubType: 0,
    sSubType: ''
  }
};

const slice = createSlice({
  name: 'salesReturnSummary',
  initialState: INITIAL_STATE,
  reducers: {
    storeDateRange(state, action) {
      state.dateRange = action.payload;
    },
    storeActiveJCd(state, action) {
      state.activeJCd = action.payload;
    },
    storeActiveWH(state, action) {
      state.activeWh = action.payload;
    },
    storeActiveProdCat(state, action) {
      state.activeProdCat = action.payload;
    },
    storeActiveProdSubCat(state, action) {
      state.activeProdSubCat = action.payload;
    },
    storeActiveBranch(state, action) {
      state.activeBrch = action.payload;
    },
    storeShowProdDetails(state, action) {
      state.showProdDetails = action.payload;
    },
    storeResult(state, action) {
      const { items, loadAll } = action.payload;
      state.result = items;
      state.showCS = items.some(item => item.libQtyCS);
      state.showSC = items.some(item => item.libQtySC);
      state.loadAll = loadAll;

      if (loadAll) state.page = 1;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setSize(state, action) {
      state.size = action.payload;
    },
    storeIxSubType(state, action) {
      state.ixSubType = action.payload;
      state.ixSub1 = 0;
      state.sSub1 = '';
    },
    storeSubAcc1(state, action) {
      state.ixSub1 = action.payload.ixSubAcc;
      state.sSub1 = action.payload.sSubAcc;
    },
    storeSubAcc2(state, action) {
      state.ixSub2 = action.payload.ixSubAcc;
      state.sSub2 = action.payload.sSubAcc;
    },
    storeSubAcc3(state, action) {
      state.ixSub3 = action.payload.ixSubAcc;
      state.sSub3 = action.payload.sSubAcc;
    },
    storeSubAcc4(state, action) {
      state.ixSub4 = action.payload.ixSubAcc;
      state.sSub4 = action.payload.sSubAcc;
    },
    storeSubAcc5(state, action) {
      state.ixSub5 = action.payload.ixSubAcc;
      state.sSub5 = action.payload.sSubAcc;
    },
    clearStoredSubAccounts(state) {
      state.ixSubType = 0;
      state.ixSub1 = 0;
      state.ixSub2 = 0;
      state.ixSub3 = 0;
      state.ixSub4 = 0;
      state.ixSub5 = 0;
      state.sSub1 = '';
      state.sSub2 = '';
      state.sSub3 = '';
      state.sSub4 = '';
      state.sSub5 = '';
    },
    setProdSubLink1(state, action) {
      state.ixProdSubLink1 = action.payload.ixProdSubLink1;
      state.sProdSubLink1 = action.payload.sProdSubLink1;
    },
    setProdSubLink2(state, action) {
      state.ixProdSubLink2 = action.payload.ixProdSubLink2;
      state.sProdSubLink2 = action.payload.sProdSubLink2;
    },
    setProdSubLink3(state, action) {
      state.ixProdSubLink3 = action.payload.ixProdSubLink3;
      state.sProdSubLink3 = action.payload.sProdSubLink3;
    },
    setProdSubLink4(state, action) {
      state.ixProdSubLink4 = action.payload.ixProdSubLink4;
      state.sProdSubLink4 = action.payload.sProdSubLink4;
    },
    setProdSubLink5(state, action) {
      state.ixProdSubLink5 = action.payload.ixProdSubLink5;
      state.sProdSubLink5 = action.payload.sProdSubLink5;
    },
    setSubWarehouse(state, action) {
      state.subWarehouse = action.payload;
    },
    resetState() {
      return INITIAL_STATE;
    },
    clear() {
      return INITIAL_STATE;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    }
  }
});

const salesReturnSummaryReducer = slice.reducer;

export const {
  storeDateRange,
  storeActiveJCd,
  storeActiveWH,
  storeActiveProdCat,
  storeActiveProdSubCat,
  storeActiveBranch,
  storeShowProdDetails,
  clearStoredSubAccounts,
  storeResult,
  setPage,
  setSize,
  storeIxSubType,
  storeSubAcc1,
  storeSubAcc2,
  storeSubAcc3,
  storeSubAcc4,
  storeSubAcc5,
  setProdSubLink1,
  setProdSubLink2,
  setProdSubLink3,
  setProdSubLink4,
  setProdSubLink5,
  setSubWarehouse,
  resetState,
  restore,
  clear
} = slice.actions;

export default salesReturnSummaryReducer;
